<template>
  <section>
    <b-form-row v-if="restrictMultipleCitySelection()">
      <b-col>
        <b-alert show variant="danger">{{
          DISPLAY_MESSAGES.MULTIPLE_CITIES_GENERAL_SETTINGS_ERROR
        }}</b-alert>
      </b-col>
    </b-form-row>
    <LoaderIcon v-if="cityDetailsLoading && !cityDetails" />
    <GeneralCity
      v-if="!cityDetailsLoading && cityDetails"
      :cityDetails="cityDetails"
      :isEditPage="true"
      :isSettingsPage="true"
      @resetCityDetails="resetCityDetails"
    />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import GeneralCity from '../cities/GeneralCity.vue'
import LoaderIcon from '../../assets/svg/loader.svg'
import { DISPLAY_MESSAGES } from '../../utilities/constants'

export default {
  name: 'Settings',
  data () {
    return {
      cityDetails: null,
      cityDetailsCopy: null,
      cityDetailsLoading: true,
      DISPLAY_MESSAGES: DISPLAY_MESSAGES
    }
  },
  components: {
    GeneralCity,
    LoaderIcon
  },
  computed: {
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant
    })
  },
  mounted () {},
  created () {
    this.getCityDetails()
  },
  methods: {
    restrictMultipleCitySelection () {
      return (
        this.selectedTenant.length > 1 ||
        this.selectedTenant.some((tenant) => tenant.tenantID === 0)
      )
    },
    getCityDetails () {
      if (this.selectedTenant.length && !this.restrictMultipleCitySelection()) {
        this.cityDetailsLoading = true
        this.cityDetails = null
        this.$store
          .dispatch('cities/getTenantSettings')
          .then((response) => {
            this.cityDetailsLoading = false
            response.data.bannerOptionSelected = response.data
              .bannerBackGrndColor
              ? 'color'
              : 'image'
            response.data.termsConditionSelected = response.data
              .termsConditionContent
              ? 'content'
              : 'link'
            response.data.privacyPolicySelected = response.data.privacyPolicyLnk
              ? 'link'
              : 'content'
            response.data.siteBackText = response.data.siteBackText
              ? response.data.siteBackText
              : ''
            this.cityDetails = response.data
            this.cloneCityDetails = cloneDeep(response.data)
          })
          .catch(() => {
            this.cityDetailsLoading = false
            this.cityDetails = null
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'FAILED',
              type: 'danger'
            })
          })
      } else {
        this.cityDetailsLoading = false
        this.cityDetails = null
      }
    },
    resetCityDetails () {
      this.cityDetails = cloneDeep(this.cloneCityDetails)
    }
  },
  watch: {
    selectedTenant (newValue, oldValue) {
      this.getCityDetails()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
